<template>
  <el-container id="the-dev-guide-layout">
    <div class="flex full-height">
      <div class="page-sidebar">
        <transition appear name="fadeRight">
          <the-dev-guide-sidebar />
        </transition>
      </div>
      <div class="overflow-y-auto h-full flex-grow">
        <div class="page-body">
          <transition name="fade" mode="out-in">
            <router-view :key="$route.fullPath" />
          </transition>
        </div>
      </div>
    </div>
  </el-container>
</template>

<script>
import TheDevGuideSidebar from './TheDevGuideSidebar';

export default {
  components: { TheDevGuideSidebar }
};
</script>

<style lang="scss">
@import '~@/scss/_variables';

#the-dev-guide-layout {
  height: 100%;
}
</style>
