<template>
  <div id="the-dev-guide-sidebar" class="standard-sidebar z-40">
    <div class="sidebar-header">
      <h2>Things to Know...</h2>
    </div>

    <el-menu
      class="sidebar-menu"
      :collapse="isCollapsed"
      :collapse-transition="false"
    >
      <template v-for="(item, index) in DevGuideNav">
        <nav-bar-item
          :key="`sidebar-menu-${item.name}`"
          :item="item"
          :index="'' + index"
          :collapsed="isCollapsed"
        />
      </template>
    </el-menu>
  </div>
</template>

<script>
import NavBarItem from '@/components/Core/Layout/NavBarItem';

import DevGuideNav from '@/navigation/devGuide';

export default {
  components: {
    NavBarItem
  },

  data() {
    return {
      isCollapsed: false,
      DevGuideNav
    };
  }
};
</script>

<style lang="scss">
@import '~@/scss/components/sidebar';
</style>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.sidebar-header {
  padding: 2em 2em 1em 2em;
  border-bottom: 1px solid $color-gray;

  .header-nav {
    margin-top: 1em;
  }
}

.sidebar-menu {
  border: none;
}
</style>
