import {
  arrowCircleODown,
  billboard,
  calendar,
  cogAlt,
  database,
  docText,
  folderEmpty,
  home3,
  play2 as play2Icon,
  tailwind,
  userPlus
} from '@/vendor/icons';

export default [
  {
    name: 'Home',
    icon: home3,
    route: { name: 'dev.guide' }
  },
  {
    name: 'Installation',
    icon: arrowCircleODown,
    route: { name: 'dev.installation' }
  },
  {
    name: 'PHP Unit',
    icon: play2Icon,
    route: { name: 'dev.php-unit' }
  },
  {
    name: 'Tailwind CSS',
    icon: tailwind,
    route: { name: 'dev.tailwind' }
  },
  {
    name: 'Element UI',
    icon: billboard,
    route: { name: 'dev.element-ui' }
  },
  {
    name: 'Dates and Date Pickers',
    icon: calendar,
    route: { name: 'dev.dates' }
  },
  {
    name: 'Sorting and Filtering',
    icon: calendar,
    route: { name: 'dev.sortAndFilter' }
  },
  {
    name: 'Logging',
    icon: docText,
    route: { name: 'dev.logging' }
  },
  {
    name: 'DevOps',
    icon: cogAlt,
    route: {
      name: 'dev.devops'
    }
  },
  {
    name: 'Icons',
    icon: folderEmpty,
    route: {
      name: 'dev.icons'
    }
  },
  {
    name: 'Data',
    icon: database,
    route: {
      name: 'dev.data'
    }
  },
  {
    name: 'Invites and Contacts',
    icon: userPlus,
    route: {
      name: 'dev.invitesAndContacts'
    }
  }
];
