<template>
  <div class="page-icons page-content">
    <div class="filter-bar -mt-3 -mx-3 flex mb-3">
      <div class="block p-3 flex-1">
        <el-form>
          <el-form-item>
            <el-input v-model="keywords" placeholder="Search..." />
          </el-form-item>
        </el-form>
      </div>
      <div class="block p-3">
        <el-tooltip>
          <div slot="content" class="font-size-lg pad">
            <h4>Add New Icons on IcoMoon!</h4>

            <ol class="margin-top">
              <li>
                Get the latest version of the <i>{{ configFile }}</i> config
                file.
              </li>
              <li>Click this button to visit icomoon.com App</li>
              <li>
                Upload the selection.json file as a starting point
                <ul class="sub-ul">
                  <li>
                    (You should see the <i>flytedesk Icons</i> group with all
                    our icons loaded)
                  </li>
                </ul>
              </li>
              <li>
                Upload / Import any new SVG or font icons you would like to add
              </li>
              <li>
                Select any icons outside the <i>flytedesk Icons</i> group that
                you want to add to the group
              </li>
              <li>
                In the <i>flytedesk Icons</i> group, click the hamburger menu
                icon:

                <ol class="sub-list">
                  <li class="sub-list-item">
                    Click <i>Move Selection here</i>
                  </li>
                  <li class="sub-list-item">Then click <i>Select All</i></li>
                  <li class="sub-list-item">
                    Finally click <i>Download JSON</i>
                  </li>
                </ol>
              </li>
              <li>
                Move the downloaded file to overwrite <i>{{ configFile }}</i>
              </li>
              <li>Run <code>node web/buildIcons.js</code></li>
            </ol>
          </div>

          <a target="_blank" href="https://icomoon.io/app/#/select">
            <el-button class="button-green">
              <icon :icon="plus" />
            </el-button>
          </a>
        </el-tooltip>
      </div>
    </div>

    <div class="info-bar -mt-3 -mx-3 flex mb-3">
      <div class="block p-3">
        <h3 v-if="keywords">
          Showing {{ filteredIcons.length }} of {{ Icons.length }} Icons
        </h3>
        <h3 v-else>{{ Icons.length }} Total Icons</h3>
      </div>
      <div class="block p-3 text-right"></div>
    </div>

    <transition-group
      name="fadeLeft"
      tag="div"
      class="flex flex-wrap animate-position"
    >
      <div
        v-for="icon in filteredIcons"
        :key="`icon-${icon.iconName}`"
        class="col-icon block p-3 text-center"
      >
        <div class="icon-image is-cenetered">
          <icon :icon="icon" />
        </div>
        <div class="icon-name">
          {{ icon.iconName }}
        </div>
        <div class="icon-label">
          {{ icon.label }}
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { extend } from 'lodash';
import { toCamelCase } from '@/utils/helpers';
import Icons, { plus } from '@/vendor/icons';

export default {
  name: 'PageIcons',
  data() {
    return {
      keywords: '',
      Icons,
      plus
    };
  },
  computed: {
    configFile() {
      return 'web/vendor/icons/selection.json';
    },

    searchableIcons() {
      let icons = [];

      for (let icon of this.Icons) {
        let label = toCamelCase(icon.iconName);

        icons.push(
          extend(
            {
              label: label,
              keywords: icon.iconName + ' ' + label
            },
            icon
          )
        );
      }

      return icons;
    },

    filteredIcons() {
      let icons = [];

      for (let icon of this.searchableIcons) {
        if (icon.keywords.match(this.keywords)) {
          icons.push(icon);
        }
      }

      return icons;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.col-icon {
  width: 12em;

  .icon-image {
    font-size: 2em;
  }

  .icon-label {
    color: $color-dark-silver;
    font-size: 0.9em;
  }
}

.sub-list {
  margin-left: 1.5em;

  .sub-list-item {
    list-style: lower-roman;
  }
}

.sub-ul {
  margin-left: 1.5em;

  li {
    list-style: disc;
  }
}
</style>
