<template>
  <div id="page-tailwind" class="page-container">
    <div class="">
      <h1>Using Tailwind CSS w/ flytedesk</h1>

      <div class="mt-5 leading-loose">
        <ul class="list-upper-roman list-p-inside">
          <li class="text-lg">
            Never used Tailwind?
            <a
              href="https://tailwindcss.com/docs/what-is-tailwind"
              target="_blank"
              >Read the Documentation!</a
            >
          </li>

          <li>
            A great starting point is to familiarize yourself with our Tailwind
            config file: <strong>web/tailwind.config.js</strong>

            https://yoksel.github.io/flex-cheatsheet/

            <ul class="list-disc list-p-inside text-dark-silver leading-normal">
              <li>
                <div class="inline-block">
                  <div class="flex">
                    <strong class="text-dark-silver">Colors:</strong>

                    <div class="ml-2">
                      We use a custom set of colors, however there may be colors
                      that are required but not available yet. You will need to
                      add the color into the list of colors.
                      <br />
                      NOTE: the colors are sorted from dark to light in each
                      category. Be sure to organize the colors appropriately.
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>

          <li>
            Any changes you make to the
            <strong>web/tailwind.config.js</strong> or any
            <strong>scss</strong> files will automatically compile and
            hot-reload your browser via the
            <strong class="text-blue">web</strong> container.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
