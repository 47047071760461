<template>
  <div id="page-logging" class="page-container">
    <h1>Logging System Messages, Errors and Alerts</h1>

    <div class="mt-5 ml-5">
      <h2>Laravel Log Messages</h2>

      <ul class="mt-4 ml-5 list-disc list-p-inside">
        <li>
          <a href="https://laravel.com/docs/5.7/logging" target="_blank"
            >Laravel Logging Documentation</a
          >
        </li>
        <li class="mt-5">
          <b>There are 8 log levels:</b>

          <ul class="ml-7 list-decimal list-p-inside">
            <li>emergency</li>
            <li>alert</li>
            <li>critical</li>
            <li>error</li>
            <li>warning</li>
            <li>notice</li>
            <li>info</li>
            <li>debug</li>
          </ul>
        </li>
        <li class="mt-5">
          <b>There are 3 log channel stacks:</b>

          <ul class="ml-7 list-decimal list-p-inside">
            <li>
              <b>stack</b>
              <code>['syslog', 'daily', 'slack', 'slackError']</code>
              <ul class="ml-5 list-circle list-p-inside">
                <li>
                  The default stack, at log level <code>error</code> or higher,
                  reports to Slack. <code>#platform-error</code> channel
                </li>
                <li>
                  The default stack will also log to the #platform-log channel
                  on log level <code>info</code> to <code>warning</code>. Log
                  levels <code>error</code> and higher will not log to
                  #platform-error.
                </li>
              </ul>
            </li>
            <li class="mt-3">
              <b>alert</b> <code>['syslog', 'daily', 'slackAlert']</code>
              <ul class="ml-5 list-circle list-p-inside">
                <li>
                  The alert stack, at log level <code>info</code> or higher,
                  reports to Slack. <code>#platform-alert</code> channel
                </li>
              </ul>
            </li>
            <li class="mt-3">
              <b>billing</b> <code>['syslog', 'daily', 'slackBilling']</code>
              <ul class="ml-5 list-circle list-p-inside">
                <li>
                  The billing stack, at log level <code>info</code> or higher,
                  reports to Slack. <code>#billing</code> channel
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li class="mt-5">
          Try to use the appropriate <i>stack</i> and <i>log level</i> according
          to your needs:
          <ul class="ml-10 list-circle">
            <li>
              If you have an error message that the Dev team should be aware of,
              use the error log level or higher.
            </li>
            <li>
              If you're just logging transactional messages (unimportant outside
              of debugging), trying to figure out how / when something happened,
              or where something went wrong then use the debug or info levels.
            </li>
            <li>
              If you're trying to inform someone outside of the Dev team about
              something that happened in the system use the <b>alert</b> stack,
              and choose the appropriate log level (anything above debug will
              show up in the Slack #platform-alert channel).
            </li>
          </ul>
        </li>
        <li class="mt-5">
          Usage within any context in Laravel:
          <div class="ml-10">
            <div class="mt-3">
              Create a log message in the <b>stack</b> default stack. This will
              only log to syslog and daily log channels. If the log level is
              error or higher it will log to Slack #platform-error channel.

              <ul class="list-circle ml-8">
                <li class="mt-3">
                  <div>
                    If there is an error in the system that a Dev should be
                    aware of immediately use error level or higher:
                  </div>
                  <div>
                    <code>\Log::error("My Error Message");</code>
                  </div>
                </li>
                <li class="mt-3">
                  <div>
                    Informational logging that should not report to slack:
                  </div>
                  <div>
                    <code>\Log::debug("My Debug Message");</code>
                  </div>
                </li>
              </ul>
            </div>
            <div class="mt-5">
              Send a log message to the <b>alert</b> stack. This will log to
              syslog, daily, and slackAlert (which logs to the Slack
              <code>#platform-alert</code> channel)

              <ul class="list-circle ml-8">
                <li class="mt-3">
                  <div>
                    Something went wrong and someone needs to do something about
                    it:
                  </div>
                  <div>
                    <code
                      >\Log::channel('alert')->error("My Alert Error
                      Message");</code
                    >
                  </div>
                </li>

                <li class="mt-3">
                  <div>
                    A transaction occurred that someone should be aware of: Like
                    a failed payment on a credit card:
                  </div>
                  <div>
                    <code
                      >\Log::channel('alert')->warning("My Alert Error
                      Message");</code
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="mt-5">
          Review the <code>ssap/config/logging.php</code> file for configuration
          details.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hasSelectedDate: false,
      selectedDate: null,
      isSelected: false,
      popperOptions: {
        positionFixed: true
      }
    };
  },

  methods: {
    dateChanged(date, selected) {
      this.isSelected = selected;
      this.selectedDate = false;
      this.hasSelectedDate = false;
      this.$nextTick(() => {
        this.hasSelectedDate = true;
        this.$nextTick(() => {
          this.selectedDate = date;
        });
      });
    }
  }
};
</script>
