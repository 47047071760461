<template>
  <div id="page-dev-guide" class="page-container">
    <div class="">
      <h1>Welcome to Flytedesk!</h1>

      <div class="mt-5">
        <h2 class="text-blue">
          Here's a list of things your should know as a Front End Dev:
        </h2>

        <ul class="list-upper-roman list-p-inside mt-6 text-lg">
          <li>
            <router-link :to="{ name: 'dev.tailwind' }"
              >Tailwind CSS
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'dev.element-ui' }"
              >Element UI
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'dev.dates' }"
              >Dates and Date Pickers
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'dev.sortAndFilter' }"
              >Sorting and Filtering
            </router-link>
          </li>

          <li>
            <b>Dates</b>
            <markdown>
              - Always use moment! Using the native Date object `new
              Date(myDate);` can cause date parsing problems in some browsers.
            </markdown>
            <br />
          </li>

          <li>
            <b>Naming Conventions</b>
            <markdown>
              - Event naming conventions (change, input, etc... just like native
              events when possible) - Components utilize kebab case - Files
              utilize camelCase - Add any others here...
            </markdown>
            <br />
          </li>

          <li>
            <b>Releasing to Staging Environments</b>
            <markdown>
              We are doing feature based release, meaning you will be putting
              your code on your own release branch / server. Of course if what
              you're working on relates to someone else's feature / stories,
              then you should release on the same branch. Do not put multiple
              unrelated features into the same branch. There are exceptions for
              really small things like bug fixes or small styling fixes, or
              things you do not deem worthy to be on its own release branch. Use
              your best judgement here. The goal is to minimize the wait time
              for releases to get through QA, so continually adding new things
              to a release branch will slow this process down. So really try to
              avoid pushing multiple things to a release unless you are putting
              them out there at the same time. Also, be sure to keep me updated
              with which release environments are active, and which ones you are
              done with so I can delete / purge them from the cluster. Those of
              you with Helm installed, of course you can clean your own releases
              up yourself.
            </markdown>
            <br />
          </li>
          <li>
            <b>Deployment</b>
            <br /><br />
            <markdown>
              #### Triggers * Push to `release/*` or `master` branches *
              Technically, pushing any branch to the
              github.com/platform/flytedesk.com repo will trigger webhooks sent
              to Gitlab. However, Gitlab will only synchronize the `release/*`
              and `master` branches. * Gitlab will run a Pipeline for any branch
              that has the `.gitlab-ci.yml` file in the root directory. So if
              you push any branch directly to the Gitlab repository
              `https://gitlab.com/flytedeskteam/platform.git` the Pipeline will
              be triggered. * `master` will be deployed to
              `https://app.flytedesk.com` * everything else will be deployed to
              `https://{branch-slug}.grapenuts.flytedesk.com` ***** ####
              Monitoring * Go to
              `https://gitlab.com/flytedeskteam/platform/pipelines` to view all
              flytedesk platform Pipelines * Watch the `#builds` channel in
              slack for deployment messages / links ***** #### Options * You can
              add commit messages to help guide the deployment process. Just
              append these commands anywhere in the commit message: *
              `--skip-tests` Will skip the testing stage * `--skip-migration`
              Will skip the migrate and seed step * If there are no changes made
              in either the web or ssap directory, they will not be built or
              tested
            </markdown>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Markdown from '@/components/Core/Markdown';

export default {
  components: {
    Markdown
  }
};
</script>
