import { render, staticRenderFns } from "./PageIcons.vue?vue&type=template&id=739484f1&scoped=true&"
import script from "./PageIcons.vue?vue&type=script&lang=js&"
export * from "./PageIcons.vue?vue&type=script&lang=js&"
import style0 from "./PageIcons.vue?vue&type=style&index=0&id=739484f1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "739484f1",
  null
  
)

export default component.exports