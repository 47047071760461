<template>
  <div id="page-data" class="page-container">
    <div class="">
      <h1>Invites and Contacts</h1>

      <div class="mt-5 leading-loose">
        <markdown>
          <!-- eslint-disable-->
          # Team Pages

          ### Basic Invite Flow
          1. Someone on the site requests to join a publisher team from the sign up page.
          2. Publisher chooses to either add or reject the request.
          3. Publisher accepts the team join request and fills in the contact type and role.
          4.
          - If the user doesn't already have an account, user gets a link to the register form and gets added as a team member.
          - If the user has an existing account, that account is added as a team member.
          5. Publisher edits role and contact type as needed.
          6. Publisher removes user from team as needed.

          *If a publisher invites a user who hasn't requested to join yet, the process just starts at step 4.*

          ### Emails
          - User gets an email confirmation when they request access to a publisher.
          - Publisher gets notified of pending join team requests in the ad shop daily summary email.
          - User gets an email when invited or request approved by a publisher / buyer.


          ### Publisher Team Page Schema
          - Mutations
          - Request to Join team (`requestPublisherAccess`)
          - Creates a record in the `invites` table with a status of `REQUESTED`.
          - Sends a confirmation email to the user and a notification email to the publisher.
          - Invite User (`sendInvite`)
          - Creates or edits an invite for the invited email with a status of `INVITE_SENT` and the selected role + contact type.
          - If a user with that email already exists, adds the user to the team.
          - If a user with that email doesn't exist, sends an email to the invited email with a link to the signup form.
          - Reject User (`rejectRequest`)
          - Updates an existing invite to the `REJECTED` status.
          - Register User (`registerUserFromPublisherInvite`)
          - Creates a user from the signup form.
          - Associates the new user with the publisher, role, and contact type from the invite.
          - Edit Team Role (`setUserRole`)
          - Updates the role for a user.
          - Edit contact (`setUserContact`)
          - Adds or removes contactables.
          - Accepts an array of true/false values for each contact type being modified.
          - Remove user (`removeUser`)
          - Deletes the PublisherUser record.
          - Removes the related invite in case the removed user needs to go through the invite flow again.


          ### Buyer Team Page Schema
          Buyer team page is very similar to the publisher team page with a few differences:
          - Users can't request to join a buyer team, they must be invited.
          - Buyer contacts can be `Creative` contacts instead of `Advisor`.

          # Contacts
          ### Contactables Trait
          Publishers and Buyers use the `Contactable` trait, which allows them to use the `contactable` polymorphic table more easily. Some important things to note:
          - The `CONTACT_TYPES` const contains the types of contacts that this model can have, as well as some rules to how they are added/removed.
          - ex. When setting a primary or billing contact, the existing primary/billing contact is removed, since they can only have one primary contact.
          ```const CONTACT_TYPES = [
          Contact::TYPE_PRIMARY => [
          'removeExisting' => true,
          ],
          Contact::TYPE_BILLING => [
          'removeExisting' => true
          ],
          Contact::TYPE_ADVISOR => [
          'removeExisting' => false
          ]
          ];```
          - The `CONTACT_FIELDS` const contains the contact types that are stored in the Publisher or Buyer's attributes.
          These are just here for backward compatibility, we should eventually move entirely to the contactables table.
          - The `ContactRequest` object validates that the inputs to create a contact are valid.

          ### Contact Types and Rules
          - Primary
          - Publishers must have only one.
          - Billing
          - Publishers can have at most one.
          - Creative
          - Only Buyers have creative contacts.
          - No limit.
          - Advisor
          - Only Publishers have advisor contacts.
          - No limit.
        </markdown>
      </div>
    </div>
  </div>
</template>

<script>
import Markdown from '@/components/Core/Markdown';

export default {
  components: {
    Markdown
  }
};
</script>
