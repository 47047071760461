<template>
  <div id="page-tailwind" class="page-container">
    <div>
      <h1>Element UI Components</h1>

      <div class="mt-5 leading-loose">
        <div class="header">
          <div class="text-lg text-green">
            Element UI is a Vue based component library.
          </div>
          <a
            href="https://element.eleme.io/#/en-US/component/installation"
            target="_blank"
            >Read the Documentation</a
          >
        </div>

        <div class="card">
          <h2>Components Only!</h2>
          In the SPA, we are only using the components from Element UI that are
          functional such as form fields, dialogs, alerts, date pickers, etc.
          <div class="mt-2 text-red text-lg font-bold">
            <div>
              We do not use Element UI for colors, typography, icons or the
              layout components!<br />
              For all those things, use
              <router-link class="text-green" :to="{ name: 'dev.tailwind' }"
                >Tailwind CSS
              </router-link>
            </div>
            <div>Please avoid the following layout components:</div>
          </div>
          <div class="text-base font-bold"></div>
          <ul class="list-disc list-p-inside leading-tight ml-3">
            <li
              v-for="deprecatedComponent in deprecatedComponents"
              :key="`deprecated-component-${deprecatedComponent}`"
            >
              {{ deprecatedComponent }}
            </li>
          </ul>
        </div>

        <div class="mt-5">
          <div class="card">
            <h2>Currently Enabled Components:</h2>
            <div class="text-dark-silver text-md">
              NOTE: Some layout components may still be enabled but they are
              deprecated, so please do not use them!
            </div>
            <ul class="mt-5">
              <li
                v-for="component of importedComponents"
                :key="`component-${component.name}`"
              >
                <a
                  :href="componentUrl(component)"
                  target="_blank"
                  :class="{ 'text-red': isDeprecated(component) }"
                >
                  {{ component.name }}
                  <span v-if="isDeprecated(component)">- Deprecated</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { importedComponents } from '@/vendor/element-ui';

export default {
  data() {
    return {
      importedComponents,
      deprecatedComponents: [
        'ElRow',
        'ElCol',
        'ElHeader',
        'ElFooter',
        'ElContainer',
        'ElAside',
        'ElMain'
      ]
    };
  },

  methods: {
    isDeprecated(component) {
      return this.deprecatedComponents.indexOf(component.name) >= 0;
    },
    componentUrl(component) {
      let url = 'https://element.eleme.io/#/en-US/component/';
      let name;

      if (component.name) {
        name = component.name;
      } else if (component.service) {
        name = component.service.name;
      } else {
        return url;
      }

      let slug = name.replace(/^El/i, '').toLowerCase();
      return url + slug;
    }
  }
};
</script>
