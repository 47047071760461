<template>
  <div id="page-dev-ops" class="page-container">
    <div class="">
      <h1>Development Operations</h1>

      <div class="text-lg font-bold mt-5">
        To get started, clone the DevOps repo: <br />
        <a href="https://github.com/Flytedesk/devops"
          >https://github.com/Flytedesk/devops</a
        >
      </div>
      <div class="mt-5 leading-loose">
        <ul class="list-upper-roman list-p-inside">
          <li class="text-lg">
            AWS EKS (Amazon Elastic Container Service for Kubernetes)

            <ul class="list-upper-alpha">
              <li>
                <a
                  href="https://docs.gitlab.com/ee/user/project/clusters/eks_and_gitlab/"
                  target="_blank"
                >
                  Setting up Kubernetes EKS deployment w/ AWS
                </a>
              </li>
              <li>
                To automatically configure the cluster and print the
                Authentication Token, Certificate and API Url, use the
                <code>gitlab/configure.sh</code> script
              </li>
            </ul>
          </li>

          <li class="text-lg">
            Setting up GitLab

            <ul class="list-upper-alpha">
              <li>
                1. Configuring the Repository
                <ul>
                  <li>Open</li>
                </ul>
              </li>

              <li></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
