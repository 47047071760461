<script>
import markdown from 'marked';
import '@/scss/components/markdown.scss';

export default {
  computed: {
    /**
     * Leading whitespace that we want to ignore in the markdown text (so we can have a prettier codebase)
     *
     * @returns {RegExp|number}
     */
    whitespaceOffset() {
      for (let line of this.lines) {
        if (line.length > 0) {
          let match = line.match(/^\s*/);

          let offset = match[0].length;

          return new RegExp(`^\\s{0,${offset}}`);
        }
      }

      return 0;
    },

    lines() {
      let lines = [];

      let index = 0;

      for (let item of this.$slots.default) {
        if (item.text) {
          lines = lines.concat(item.text.split('\n'));
        } else {
          // Uniquely identify this node so we can later inject it into the markdown
          item.ref = '__markedNode' + index++;
          lines.push(item);
        }
      }

      return lines;
    }
  },

  mounted() {
    // We have rendered all the markdown and HTML DOM nodes separately. We need to go back and inject
    // the DOM nodes in their correct position mixed in the markdown HTML
    for (let ref of Object.keys(this.$refs)) {
      if (ref !== 'markdown') {
        this.injectNode(ref, this.$refs[ref].children[0], this.$refs.markdown);
      }
    }
  },

  methods: {
    /**
     * Injects a Vnode that was rendered as HTML in the DOM into the original position in the Markdown HTML
     *
     * @param ref
     * @param node
     * @param context
     */
    injectNode(ref, node, context) {
      if (context.id === ref) {
        context.replaceWith(node);
        return true;
      } else if (context.children) {
        for (let child of context.children) {
          if (this.injectNode(ref, node, child)) {
            return true;
          }
        }
      }

      return false;
    }
  },
  render(c) {
    let root = c('div', { class: 'markdown' }, []);

    let html = '';

    for (let line of this.lines) {
      if (typeof line === 'string') {
        html += line.replace(this.whitespaceOffset, '') + '\n';
      } else {
        // Add a placeholder node that we will replace after the DOM is rendered (add a space at the end to maintain spacing)
        html += `<span id="${line.ref}"> </span> `;
        root.children.push(c('div', { ref: line.ref }, [line]));
      }
    }

    // Add the markdown as raw HTML to the DOM
    root.children.push(
      c('div', { ref: 'markdown', domProps: { innerHTML: markdown(html) } })
    );

    return root;
  }
};
</script>
