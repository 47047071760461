<template>
  <div id="page-php-unit" class="page-container">
    <markdown>
      # PHP Unit testing in SSAP ## Running the unit tests * run `docker-compose
      exec ssap bash` to proxy into the Docker SSAP container * inside the
      container run `vendor/bin/phpunit` to run all the unit tests * run
      `vendor/bin/phpunit tests/Unit/{test folder}` to run a specific folder in
      the Unit tests ## Integrating PHP Unit w/ PHP Storm * Enable the SSH
      Remote Run plugin (should be bundled by default) * Enable the Docker and
      PHP Docker plugins (also bundled by default) * [Configure Docker as
      described here](https://www.jetbrains.com/help/phpstorm/docker.html) *
      [Configure a remote PHP
      interpreter](https://www.jetbrains.com/help/phpstorm/configuring-remote-interpreters.html)
      * You need to configure it to use the Docker
      `flytedesk/platform/ssap:latest` container * Set the php path to `php` *
      Under the CLI Interpreter settings, add the `docker-compose.yaml` and
      `docker-compose.override.yaml` files to the configuration files * Set the
      default configuration file to /opt/project/phpunit.xml * Under Languages &
      Frameworks | PHP * Set the PHP language level to php 7.2 * Set the CLI
      Interpreter to the Docker CLI Interpreter for ssap you previously created
      * Click Browse on the Docker Container input, and set the network mode to
      `platform` instead of `bridge` (which is the default)
    </markdown>
  </div>
</template>

<script>
import Markdown from '@/components/Core/Markdown';

export default {
  components: {
    Markdown
  }
};
</script>
