<template>
  <div id="page-sort-and-filter" class="page-container">
    <h1>Sorting and Filtering</h1>

    <div class="mt-5">
      <h2>Filtering</h2>
      <ul class="list-circle list-p-inside ml-3 mt-2">
        <li>Filters should always be passed as an object.</li>
        <li>
          Most filters will use a generalized filtering system. This allows for
          robust filtering on any query, with minimal effort on the backend.
        </li>
        <li>
          Most GraphQL queries will be querying against a relational database.
        </li>
        <li>
          You can filter on any field that is on a table that is part of the
          query created by the GraphQL endpoint.

          <ul class="list-disc list-p-inside ml-3">
            <li>
              Try clicking on this example:
              <code
                class="is-clickable"
                @click="setFilter(filters.examplePublication)"
              >
                {{ filters.examplePublication }}
              </code>
            </li>
          </ul>
        </li>
        <li>
          For any tables that are not already a part of the query, you can
          specify which relational table to join, and filter on a field from
          that table directly

          <ul class="list-disc list-p-inside ml-3">
            <li>
              Try clicking on this example:
              <code class="is-clickable" @click="setFilter(filters.campusName)">
                {{ filters.campusName }}
              </code>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="flex mt-5">
      <div class="flex-shrink w-2/3">
        <label for="filter-str-textarea" class="font-bold">Filter JSON:</label>
        <textarea
          id="filter-str-textarea"
          v-model="filterStr"
          class="code-editor w-full h-64 mt-1"
        />
      </div>
      <div class="flex-shrink w-1/3 ml-5">
        <label class="font-bold">More Examples:</label>
        <div class="mt-3">
          <code class="is-clickable" @click="setFilter(filters.lastMonth)">
            Publishers Created Last Month
          </code>
        </div>

        <div class="mt-2">
          <code class="is-clickable" @click="setFilter(filters.highEnrollment)">
            Publishers in Campuses w/ High Enrollment
          </code>
        </div>

        <div class="mt-2">
          <code class="is-clickable" @click="setFilter(filters.activeFullPage)">
            Publishers w/ Active products named "Full Page"
          </code>
        </div>
      </div>
    </div>

    <div class="mt-5 flex">
      <transition mode="out-in" name="fade">
        <div v-if="!isSuppliersLoading">
          <div class="flex-shrink">
            <el-button
              :disabled="!isFilterValid"
              class="button-blue"
              @click="updateFilter"
            >
              Submit
            </el-button>
          </div>
          <div>
            <transition name="fade">
              <el-alert
                v-if="!isFilterValid"
                :closable="false"
                type="error"
                title="Your filter JSON is invalid."
                class="ml-5"
              />
            </transition>
          </div>
        </div>
        <div v-else class="flex-shrink w-1/3">
          <loader-box :height="2.7" />
        </div>
      </transition>
    </div>

    <suppliers-query :pager="pager" :loading.sync="isSuppliersLoading">
      <template slot-scope="{ data: paginator }">
        <div class="relative">
          <el-table
            :data="!isSuppliersLoading && paginator ? paginator.data : []"
            border
            class="w-full mt-6 el-table-slim"
            :default-sort="pager.sort[0]"
            @sort-change="sortColumns"
          >
            <table-empty-slot
              slot="empty"
              :is-loading="isSuppliersLoading"
              :has-data="!!paginator"
              empty-message="There were no publishers that matched your filter criteria"
              error-message="There was a problem loading your ads. Please try again later."
            />

            <el-table-column label="ID" width="80" prop="id" sortable="id">
              <div slot-scope="{ row }">
                {{ row.id }}
              </div>
            </el-table-column>

            <el-table-column label="Publication" prop="name" sortable="name">
              <div slot-scope="{ row }">
                <div>{{ row.name }}</div>
                <div>{{ row.campus.name }}</div>
              </div>
            </el-table-column>

            <el-table-column
              label="Enrollment"
              width="120"
              sortable="campus.data.enrollment"
            >
              <div slot-scope="{ row }">
                <div v-if="row.campus.data">
                  {{ row.campus.data.enrollment }}
                </div>
                <div v-else>N/A</div>
              </div>
            </el-table-column>

            <el-table-column
              label="Created Date"
              align="center"
              width="120"
              prop="created_at"
              sortable="created_at"
            >
              <div slot-scope="{ row }">
                {{ row.created_at | date }}
              </div>
            </el-table-column>
          </el-table>

          <pagination
            v-if="paginator && paginator.paginatorInfo.total > 0"
            :info="paginator.paginatorInfo"
            :pager.sync="pager"
            class="mt-5"
          />
        </div>
      </template>
    </suppliers-query>
  </div>
</template>

<script>
import moment from 'moment';
import Pagination from '@/components/Core/Table/Pagination';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import { LoaderBox } from '@/components/Core/Loaders';
import { SuppliersQuery } from '@/components/Queries';
import { sortFilter } from '@/utils/sortFilter';
import { datetimeDb } from '@/utils/filters';

export default {
  components: {
    LoaderBox,
    Pagination,
    SuppliersQuery,
    TableEmptySlot
  },

  data() {
    const currentTime = moment();

    const lastMonthStart = datetimeDb(
      currentTime.subtract(1, 'month').startOf('month')
    );
    const lastMonthEnd = datetimeDb(currentTime.endOf('month'));

    return {
      isSuppliersLoading: false,
      filterStr: '',
      editorOptions: {
        scrollBeyondLastLine: false
      },
      pager: {
        perPage: 10,
        page: 1,
        sort: [{ column: 'id', order: 'ascending' }],
        filter: {
          created_at: {
            '>=': '2018-01-01'
          }
        }
      },

      filters: {
        examplePublication: '{"id": 1, "name": "The Flytedesk Times"}',
        campusName: '{"campus.name": {"like": "%hi%"}}',
        lastMonth: `{"created_at": {">=": "${lastMonthStart}", "<=": "${lastMonthEnd}"}}`,
        highEnrollment: `{"campus.data.enrollment": {">=": 10000, "null": false}}`,
        activeFullPage:
          '{"mediums.properties.collections.products.name": "Full Page", "mediums.properties.collections.products.is_enabled": 1}'
      }
    };
  },

  computed: {
    isFilterValid() {
      try {
        JSON.parse(this.filterStr);
        return true;
      } catch (e) {
        return false;
      }
    }
  },

  mounted() {
    this.filterStr = JSON.stringify(this.pager.filter);
  },

  methods: {
    ...sortFilter,
    setFilter(filter) {
      this.filterStr = filter;
      this.updateFilter();
    },
    updateFilter() {
      try {
        this.pager.filter = JSON.parse(this.filterStr);
      } catch (e) {
        this.pager.filter = '';
      }
    }
  }
};
</script>
