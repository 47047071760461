<template>
  <div id="page-dates" class="page-container">
    <h1>Dates and Date Pickers</h1>

    <div class="mt-5">
      <el-popover
        v-if="hasSelectedDate"
        ref="popover"
        :value="!!selectedDate"
        :reference="selectedDate"
        :popper-options="popperOptions"
      >
        <div>
          The date {{ selectedDate.dateObj | date }} is
          {{ isSelected ? 'selected' : 'not selected' }}
        </div>
      </el-popover>

      <flyte-multi-picker @date-click="dateChanged" />
    </div>
  </div>
</template>

<script>
import FlyteMultiPicker from '@/components/Core/FlytePicker/FlyteMultiPicker';

export default {
  components: { FlyteMultiPicker },

  data() {
    return {
      hasSelectedDate: false,
      selectedDate: null,
      isSelected: false,
      popperOptions: {
        positionFixed: true
      }
    };
  },

  methods: {
    dateChanged(date, selected) {
      this.isSelected = selected;
      this.selectedDate = false;
      this.hasSelectedDate = false;
      this.$nextTick(() => {
        this.hasSelectedDate = true;
        this.$nextTick(() => {
          this.selectedDate = date;
        });
      });
    }
  }
};
</script>
